<template>
  <div>
    <!-- <p>上传参数：{{aa}}</p>
    <p>接收的参数：{{bb}}</p> -->
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  async mounted() {
    await this.$toast.loading("请稍后");
    //调用函数
    await this.wxparameter(
      this.$route.query.money,
      this.$route.query.orderType,
      this.$route.query.url,
      this.$route.query.panment,
      this.$route.query.WIDsubject,
      this.$route.query.WIDbody,
      this.$route.query.isdetailspay
    );
  },
  data() {
    return {
      aa: "", //打印上传
      bb: "", //打印返回
      a: "",
    };
  },
  methods: {
    /**
     *
     * WIDtotal_amount：金额(必传)
     * order_type：订单类型 1.充值、2.购买。3、购买套餐、4.活动购买、5.购买套餐包、6.请求报告、7.批量购买、8.自查购买、9.退款（必传）
     * url：跳转到哪个页面
     * id：报告id
     * code：微信公众号授权code（支付需要使用）
     * panment：支付方式（支付方式 1.余额、2.微信、3.支付宝）
     * WIDsubject:订单名称
     * WIDbody：描述
     */
    wxparameter(
      WIDtotal_amount,
      order_type,
      url,
      panment = "2",
      WIDsubject = "购买套餐",
      WIDbody = "描述",
      isdetailspay = "" //是否是从充值记录菜单进行充值
    ) {
      let uppayobj = "";
      if (isdetailspay == "true") {
        uppayobj = {
          reqType: "cost",
          order_no: this.$route.query.order_no,
          code: this.$route.query.code,
        };
      } else {
        uppayobj = {
          reqType: "cost",
          WIDsubject: WIDsubject,
          WIDtotal_amount: WIDtotal_amount,
          // WIDtotal_amount: "0.01",
          // WIDtotal_amount: this.ispay == true ? this.money : this.taomoney,
          WIDsubject: WIDsubject,
          WIDbody: WIDbody,
          panment: panment,
          //order_type: this.ip == "1" ? "8" : "1", //	订单类型 1.充值、2.购买。3、购买套餐、4.活动购买、5.购买套餐包、6.请求报告、7.批量购买、8.自查购买、9.退款
          order_type: order_type,
          id: this.$route.query.id,
          code: this.$route.query.code,
        };
      }

      this.aa = uppayobj;
      let httpurl = "";
      if (isdetailspay == "true") {
        httpurl = "/firm/v1/cost/wechatPhonePay2";
      } else {
        httpurl = "/firm/v1/Login/wechatPhonePay";
      }
      this.$http
        .post(httpurl, uppayobj)
        .then((res) => {
          this.bb = JSON.parse(res.data).data;
          // this.bbb = JSON.parse(res.data)

          this.a = {
            appId: JSON.parse(res.data).data.appId,
            timeStamp: JSON.parse(res.data).data.timeStamp,
            nonceStr: JSON.parse(res.data).data.nonceStr,
            package: JSON.parse(res.data).data.package,
            signType: "MD5",
            paySign: JSON.parse(res.data).data.paySign,
          };
          this.wxpay(url);
        })
        .catch((res) => {
          // 防止重复点击
        });
    },
    wxpay(url) {
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: this.a.appId,
          nonceStr: this.a.nonceStr,
          package: this.a.package,
          paySign: this.a.paySign,
          signType: this.a.signType,
          timeStamp: this.a.timeStamp,
        },
        function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            alert("恭喜您，支付成功!更新可能会有5～10秒延迟!");
            if (url == "" || url == undefined) {
              window.history.go(-1);
            } else {
              window.location.href = url;
            }
          } else {
            Toast("支付取消");
            window.history.go(-1);
          }
        }
      );
    },
  },
};
</script>
<style scoped></style>
